<template>
  <div class="max-w-[1500px] relative mx-auto">
    <Header/>

    <div class="max-w-[1500px] mx-auto px-4 mt-[40px] sm:mt-[50px] md:mt-0 flex flex-wrap">

      <div class="w-full flex font-titleSemiBold text-4.3xl">

        <div class="div-title uppercase text-center pb-3.5 md:mb-8 w-full">
          <h1 class="text-4xl mb-2 md:mb-7 text-center border-b border-black pb-2">
            My Account
          </h1>
        </div>

      </div>

      <div class="flex flex-col md:flex-row md:space-x-4 md:space-x-8 w-full">
        <div class=" md:basis-1/4  relative">
          <div class="flex flex-col text-2xl lg:text-3xl.5 font-titleSemiBold sticky top-5 ">
            <NuxtLink to="/users/account"
                      class="px-3 lg:px-6 py-2 border border-black  text-title relative mb-2"
                      :class="userAccountTabs === 'AccountTab' ? 'bg-black text-white font-body-font' : 'bg-white text-black'"
            >
              Account Information
              <div class="absolute top-[40%] right-6">
                <img src="/icons/arrowRight-white.svg" width="15" height="20" alt="icon arrow">
              </div>
            </NuxtLink>
            <NuxtLink to="/users/address"
                      class="px-3 lg:px-6 py-2 border border-black  text-title relative mb-2"
                      :class="userAccountTabs === 'ShippingAddresses' ? 'bg-black text-white font-body-font' : 'bg-white text-black'"
            >
              Shipping Addresses
              <div class="absolute top-[40%] right-6">
                <img src="/icons/arrowRight-white.svg" width="15" height="20" alt="icon arrow">
              </div>
            </NuxtLink>
            <NuxtLink to="/users/orders"
                      class="px-3 lg:px-6 py-2 border border-black  text-title relative mb-2"
                      :class="userAccountTabs === 'OrdersHistory' ? 'bg-black text-white font-body-font' : 'bg-white text-black'"
            >
              Orders History
              <div class="absolute top-[40%] right-6">
                <img src="/icons/arrowRight-white.svg" width="15" height="20" alt="icon arrow">
              </div>
            </NuxtLink>
            <NuxtLink to="/users/subscriptions"
                      class="px-3 lg:px-6 py-2 border border-black  text-title relative mb-2"
                      :class="userAccountTabs === 'Subscriptions' ? 'bg-black text-white font-body-font' : 'bg-white text-black'"
            >
              Subscriptions
              <div class="absolute top-[40%] right-6">
                <img src="/icons/arrowRight-white.svg" width="15" height="20" alt="icon arrow">
              </div>
            </NuxtLink>
            <NuxtLink to="/users/favorite-photos"
                      class="px-3 lg:px-6 py-2 border border-black  text-title relative mb-2"
                      :class="userAccountTabs === 'FavoritePhotos' ? 'bg-black text-white font-body-font' : 'bg-white text-black'"
            >
              Favorite Photos
              <div class="absolute top-[40%] right-6">
                <img src="/icons/arrowRight-white.svg" width="15" height="20" alt="icon arrow">
              </div>
            </NuxtLink>
            <NuxtLink to="/users/digital-photos"
                      class="px-3 lg:px-6 py-2 border border-black  text-title relative mb-2"
                      :class="userAccountTabs === 'DigitalPhotos' ? 'bg-black text-white font-body-font' : 'bg-white text-black'"
            >
              Digital Photos
              <div class="absolute top-[40%] right-6">
                <img src="/icons/arrowRight-white.svg" width="15" height="20" alt="icon arrow">
              </div>
            </NuxtLink>
          </div>
        </div>
        <div class="md:basis-3/4 mb-10">
          <slot>

          </slot>
        </div>
      </div>
    </div>

    <Footer/>

  </div>
</template>

<script setup>
import {computed, ref} from "vue";
const route = useRoute();

const userAccountTabs = computed(()=>{
  if(route.href.includes('account')){
    return 'AccountTab';
  }
  else if(route.href.includes('address')){
    return 'ShippingAddresses';
  }
  else if(route.href.includes('orders')){
    return 'OrdersHistory';
  }
  else if(route.href.includes('subscriptions')){
    return 'Subscriptions';
  }
  else if(route.href.includes('favorite-photos')){
    return 'FavoritePhotos';
  }
  else if(route.href.includes('digital-photos')){
    return 'DigitalPhotos';
  }

  return 'AccountTab';
});



</script>
